const firebaseConfig = {
  apiKey: 'AIzaSyCnhNewPAu9iLkuIW1RP3pKJ1YqT8EeSjk',
  authDomain: 'tofupancake-typeracer.firebaseapp.com',
  projectId: 'tofupancake-typeracer',
  storageBucket: 'tofupancake-typeracer.appspot.com',
  messagingSenderId: '162167912214',
  appId: '1:162167912214:web:4c29b056ccff385a2bc237',
  measurementId: 'G-2HT59P3TRW',
};

export default firebaseConfig;
